<!-- This is the Preop Data page -->
<template>
    <b-container fluid class="preop-data" v-if="loaded">
        <PatientDetailsHeaderBar :doctor="preopdata.doctor">
            <template #buttons>
                <CalculatorButtons @saved="onSaved" v-if="canEdit" />
            </template>
        </PatientDetailsHeaderBar>

        <b-row class="pt-2">
            <b-col xl="6">
                <PreOpEyeSide
                    ref="PreOpEyeSideOD"
                    v-on="$listeners"
                    @reset-opposite-eye="resetOppositeEye"
                    :side="EyeSides.OD"
                />
            </b-col>
            <b-col xl="6">
                <PreOpEyeSide
                    ref="PreOpEyeSideOS"
                    v-on="$listeners"
                    @reset-opposite-eye="resetOppositeEye"
                    :side="EyeSides.OS"
                />
            </b-col>
        </b-row>
        <p class="text-right font-weight-lighter mt-2">
            <small class="float-left">{{ t('disclaimer') }} </small>
            <small>
                {{ t('calculator_version') }} V{{ calculatorApiVersion }}
                {{ region }}
            </small>
        </p>
    </b-container>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import {get, upperCase} from 'lodash';
import PatientDetailsHeaderBar from '@/views/patientdetails/components/PatientDetailsHeaderBar.vue';
import PreOpEyeSide from '@/views/CalculatorDevice/components/PreOpEyeSide.vue';
import CalculatorButtons from '@/views/CalculatorDevice/components/CalculatorButtons.vue';
import {EyeSides} from '@/store/modules/preopdata';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'PreOpData',
    components: {
        PatientDetailsHeaderBar,
        PreOpEyeSide,
        CalculatorButtons,
    },
    props: {
        isCopying: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            EyeSides,
            loaded: false,
        };
    },

    computed: {
        ...mapState({
            calculatorApiVersionInfo: (state) => state.apiversion.calculatorApiVersionInfo,
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters({
            preopdata: 'preopdata/preopdata',
            region: 'zone/currentZone',
            currentPatient: 'patient/currentPatient',
        }),

        canEdit() {
            return this.checkPermissions(
                {
                    [PERMISSIONS.PATIENT_PREOP_DATA]: PERMISSIONS_VALUES.READ_WRITE,
                },
                this.permissions,
                this.currentUser.accessPermissions
            );
        },

        patientId() {
            return this.$route.params.patientId;
        },

        preOpDataSetId() {
            return this.$route.params.preOpDataSetId;
        },

        initialActiveSide() {
            const side = upperCase(this.$route.query.side || '');
            return Object.values(EyeSides).includes(side) ? side : EyeSides.OD;
        },

        calculatorApiVersion() {
            return this.calculatorApiVersionInfo?.version;
        },
    },

    methods: {
        checkPermissions,
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
            hideWarningModal: 'message/hideWarningModal',
            resetPreOpdata: 'preopdata/resetPreopdata',
            setActiveEye: 'preopdata/setActiveEye',
            setLensCertificationSummary: 'preopdata/setLensCertificationSummary',
        }),
        setInitialActiveEye() {
            this.setActiveEye(this.initialActiveSide);
            const oppositeSide = Object.values(EyeSides).filter(
                (side) => side !== this.initialActiveSide
            )[0];
            if (get(this.preopdata, `preOpData[${oppositeSide}].monocular`, false))
                this.setActiveEye(oppositeSide);
        },
        resetOppositeEye(oppositeEye) {
            this.$refs[`PreOpEyeSide${oppositeEye}`].$v.$reset();
        },
        onSaved(preopdata) {
            [this.$refs.PreOpEyeSideOD, this.$refs.PreOpEyeSideOS].forEach((component) =>
                component.$v.$reset()
            );
            if (!this.preOpDataSetId) {
                this.$router.push({
                    name: 'PreOpData',
                    params: {
                        preOpDataSetId: preopdata.preOpDataSetId,
                        patientId: this.patientId,
                    },
                });
            }
        },
    },

    async created() {
        const {decimalSeparator} = this.currentUser;
        !this.isCopying && this.resetPreOpdata({patientId: this.patientId, decimalSeparator});

        let requests = [];

        /* Fetch the patient personal details. */
        requests.push(
            this.$store
                .dispatch('patient/fetchPatient', {
                    patientId: this.patientId,
                })
                .catch((err) => {
                    this.$store.commit('showAlert', {
                        response: err.response,
                        fallbackMsg: 'Failed to fetch patient',
                        seconds: 5,
                    });
                })
        );

        /* Fetch the patient's lenses data. */
        requests.push(
            this.$store
                .dispatch('preopdata/fetchValidationRanges', {
                    patientId: this.patientId,
                })
                .catch((err) => {
                    this.$store.commit('showAlert', {
                        response: err.response,
                        fallbackMsg: 'Failed to fetch preop validation ranges',
                        seconds: 5,
                    });
                })
        );

        requests.push(
            this.$store.dispatch('apiversion/fetchCalculatorApiVersionInfo').catch((err) => {
                this.$store.commit('showAlert', {
                    response: err.response,
                    fallbackMsg: 'Failed to fetch calculator api version info',
                    seconds: 5,
                });
            })
        );

        if (this.preOpDataSetId) {
            /* Fetch the patient's calculation data. */
            requests.push(
                this.$store
                    .dispatch('preopdata/fetchPreopdata', {
                        patientId: this.patientId,
                        preOpDataSetId: this.preOpDataSetId,
                        decimalSeparator,
                    })
                    .catch((err) => {
                        this.$router.push({name: 'NotFound'});
                        this.$store.commit('showAlert', {
                            response: err.response,
                            fallbackMsg: 'Failed to fetch current pre-op data set',
                            seconds: 5,
                        });
                    })
            );
        }

        try {
            await Promise.allSettled(requests);
            if (!this.preOpDataSetId) {
                // if we are adding a new pre-op data we need to load the summary of certifications after the current patient data is loaded
                await this.$store
                    .dispatch('doctors/fetchLensCertificationSummary', this.currentPatient.doctorId)
                    .then(({results}) => this.setLensCertificationSummary(results))
                    .catch((err) => {
                        this.$store.commit('showAlert', {
                            response: err.response,
                            fallbackMsg: 'Failed to fetch doctor certification summary',
                            seconds: 5,
                        });
                    });
            }
            this.setInitialActiveEye();
        } finally {
            // All patient data is loaded
            this.loaded = true;
        }
    },

    destroyed() {
        this.hideWarningModal();
    },
};
</script>

<style>
.gray-switch .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: gray;
    border-color: gray;
}

.class1 span {
    font-size: 0.7rem;
    line-height: 1rem;
    height: 14px;
    vertical-align: middle;
    text-align: center;
    position: absolute;
    top: calc(10%);
    left: 10%;
    right: 10%;
}

[role='radiogroup'] label.btn.btn-secondary.btn-sm {
    width: 50%;
    line-height: 1.28rem;
}
</style>
